// PageTemplatePortrait.js
import { Page, Image, View, StyleSheet } from '@react-pdf/renderer';
import memorandum from '../../../../../_websoft/assets/images/Memorandum-portrait-bez-footera.jpg';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        position: 'relative',
        width: '100%',
        height: '100%',
    },
    backgroundImage: {
        position: 'absolute',
        minWidth: '100%',
        minHeight: '100%',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1,
    },
    content: {
        marginTop: 0,
        marginHorizontal: 0,
        zIndex: 2,
        flex: 1,
    },
});

const PageTemplatePortrait = ({ children }) => (
    <Page size='A4' orientation='portrait' style={styles.page}>
        <View style={styles.content}>
            <Image style={styles.backgroundImage} src={memorandum} />
            {children}
        </View>
    </Page>
);

export default PageTemplatePortrait;
