import { Table } from '@tanstack/react-table';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';

// Move this outside the exportExcel function
export interface HeaderFormatData {
  Promet1OdPreformatted?: string;
  Promet1DoPreformatted?: string;
  Promet2OdPreformatted?: string;
  Promet2DoPreformatted?: string;
  Promet3OdPreformatted?: string;
  Promet3DoPreformatted?: string;
}

export default async function exportExcel(
    table: Table<any>,
    filename: string,
    applyFilters = true,
    extraData?: HeaderFormatData
) {
  const wb = new Workbook();
  const ws = wb.addWorksheet('Sheet 1');

  function mapHeaderToString(headerId: string, data?: HeaderFormatData): string {
    switch (headerId) {
      case 'promet1':
        return data?.Promet1OdPreformatted && data?.Promet1DoPreformatted
            ? `Promet 1 (${data.Promet1OdPreformatted} / ${data.Promet1DoPreformatted})`
            : 'Promet 1';
      case 'promet2':
        return data?.Promet2OdPreformatted && data?.Promet2DoPreformatted
            ? `Promet 2 (${data.Promet2OdPreformatted} / ${data.Promet2DoPreformatted})`
            : 'Promet 2';
      case 'promet3':
        return data?.Promet3OdPreformatted && data?.Promet3DoPreformatted
            ? `Promet 3 (${data.Promet3OdPreformatted} / ${data.Promet3DoPreformatted})`
            : 'Promet 3';
      default:
        return headerId;
    }
  }

  const lastHeaderGroup = table.getHeaderGroups().at(-1);
  if (!lastHeaderGroup) {
    console.error('No header groups found', table.getHeaderGroups());
    return;
  }

  // Ensure all columns are included (removes filtering by visibility)
  ws.columns = lastHeaderGroup.headers.map((header) => ({
    header: mapHeaderToString(header.id, extraData),
    key: header.id,
    width: 20,
  }));

  // Choose rows based on whether filters should be applied
  const exportRows = applyFilters ? table.getFilteredRowModel().rows : table.getCoreRowModel().rows;

  // Include all columns when adding rows
  exportRows.forEach((row) => {
    const cells = row.getAllCells(); // Changed from getVisibleCells() to getAllCells()
    const values = cells.map((cell) => cell.getValue() ?? '');
    ws.addRow(values);
  });

  const buf = await wb.xlsx.writeBuffer();
  saveAs(new Blob([buf]), `${filename}.xlsx`);
}
