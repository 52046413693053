import {useEffect, useRef, useState} from 'react'
import {ArtikliModel, ParamsModelArtikli} from '../../models/ArtikliModel'
import {
    SortingState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import React from 'react'
import notifyToast from '../../components/Toast/Toast'
import {KTCard, KTCardBody, KTIcon} from '../../../_websoft/helpers'
import {ToastContainer} from 'react-toastify'
import TableSearch from '../../components/TableSearch/TableSearch'
import ExportButton from '../../components/TableExportToExcel/ExportButton'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import TablePagination from '../../components/TablePagination/TablePagination'
import {PaginationModel} from '../../models/PaginationModel'
import {ParamsModelTable, ThermoBoardPaketiModel} from '../../models/ThermoBoardPaketi'
import {getAllTBPaketi} from '../../api/thermoBoardPaketi'
import IndeterminateCheckbox from '../../components/IndeterminateCheckbox/IndeterminateCheckbox'
import MinMaxFilter from '../../components/MinMaxFilter/MinMaxFilter'
import UpdateStatusComponent from './UpdateStatusComponent'
import moment from "moment";


const ThermoBoardPaketi: React.FC = () => {
    const [data, setData] = useState<ThermoBoardPaketiModel[]>([])
    const [loading, setLoading] = useState(false)
    const [rowSelection, setRowSelection] = useState({})
    const [pagination, setpagination] = useState<PaginationModel>({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    })

    const [params, setparams] = useState<ParamsModelTable>({per_page: 10, page: 1})

    const [sorting, setSorting] = useState<SortingState>([])
    const [globalFilter, setGlobalFilter] = React.useState('')
    const [idNaloga, setidNaloga] = useState<string>()

    const [paketiBlok, setPaketBlok] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        const {id} = sorting[0] ?? ''
        data.sort((a, b) => a[id] - b[id])
    }, [sorting])

    const statusCodes: { [key: number]: [string, string] } = {
        0: ['Isporučen', '#FFFFFF'],
        1: ['Raspoloživ', '#53F55A'],
        2: ['Rezervisan', '#FF4C44'],
    }
    const getCellColor = (cell, row) => {
        // Color for status
        if (cell.column.id === 'Status')
            return cell.getValue() ? statusCodes[cell.getValue()][1] : '#FFFFFF'
        // Color default
        return '#FFFFFF'
    }
    const columns = [
        {
            id: '#',
            header: ({table}) => (
                <div className='p-2' style={{width: '10px'}}>
                    {' '}
                    {/* Set width here */}
                    <IndeterminateCheckbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler(),
                        }}
                    />
                </div>
            ),
            cell: ({row, getValue}) => (
                <div className='p-2' style={{width: '10px'}}>
                    {' '}
                    {/* Set width here */}
                    <IndeterminateCheckbox
                        {...{
                            checked: row.getIsSelected(),
                            indeterminate: row.getIsSomeSelected(),
                            onChange: row.getToggleSelectedHandler(),
                        }}
                    />
                </div>
            ),
            // Other properties...
        },
        {
            id: 'Paket',
            header: 'Paket',
            accessorKey: 'Id',
        },

        {
            id: 'Tip Panela',
            header: 'Tip Panela',
            accessorKey: 'nalozi.tip_panela.Naziv',
        },
        {
            id: 'Širina',
            header: 'Širina',
            accessorKey: 'Sirina',
        },
        {
            id: 'Dužina',
            header: 'Dužina',
            accessorKey: 'Duzina',
        },
        {
            id: 'Kom.',
            header: 'Kom.',
            accessorKey: 'Komada',
        },
        {
            header: 'Proizvedeno',
            accessorKey: 'Proizvedeno',
            cell: ({row}) => (
                <div
                    style={{
                        color: row.original.Proizvedeno === 1 ? 'green' : 'red',
                    }}
                >
                    {row.original.Proizvedeno === 1 ? 'DA' : 'NE'}
                </div>
            ),
        },
        {
            id: 'Datum',
            header: 'Datum proizvodnje',
            accessorFn: (row) =>
                row.ProizvedenDatum && moment(row.ProizvedenDatum).isValid()
                    ? moment(row.ProizvedenDatum).format('DD-MM-YYYY')
                    : '',
        },
        {
            header: 'Isporučeno',
            accessorKey: 'Isporuceno',
            cell: ({row}) => (
                <div
                    style={{
                        color: row.original.Isporuceno === 1 ? 'green' : 'red',
                    }}
                >
                    {row.original.Isporuceno === 1 ? 'DA' : 'NE'}
                </div>
            ),
        },
        {
            id: 'Datum',
            header: 'Isporučeno Datum',
            accessorFn: (row) =>
                row.DatumIsporuke && moment(row.DatumIsporuke).isValid()
                    ? moment(row.DatumIsporuke).format('DD-MM-YYYY')
                    : '',
        },
        {
            header: 'Tip paketa',
            accessorKey: 'nalozi.TipPaketa',
            cell: ({row}) => (
                <div
                    style={{
                        color: row.original.nalozi.TipPaketa === 1 ? 'green' : 'red',
                    }}
                >
                    {row.original.nalozi.TipPaketa === 1 ? 'MEGA' : 'OBIČAN'}
                </div>
            ),
        },
        {
            id: 'Status.',
            header: 'Status.',
            accessorKey: 'Status',
            cell: ({row}) => statusCodes[row.original.Status][0],
        },
    ]
    const table = useReactTable({
        data: data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting: sorting,
            globalFilter: globalFilter,
            rowSelection,
        },
        onGlobalFilterChange: setGlobalFilter,
        getFilteredRowModel: getFilteredRowModel(),
        onSortingChange: setSorting,
        enableRowSelection: true, //enable row selection for all rows
        onRowSelectionChange: setRowSelection,
    })

    const showPaketi = () => {
        setLoading(true)
        if (idNaloga) {
            // Include this value in your API call parameters
            const apiParams = {
                ...params,
                idNaloga: idNaloga,
            }

            getAllTBPaketi(apiParams)
                .then((response) => {
                    const data = response.data['data']
                    setData(data)
                    const totalPage = Math.floor(response.data['total'] / response.data['per_page'] + 1)
                    setpagination({
                        curPage: response.data['current_page'],
                        totalPage: totalPage,
                        perPage: params.per_page,
                    })
                })
                .catch((error) => {
                    console.error('Error: ', error)
                    notifyToast(false, error)
                })
                .finally(() => {
                    setLoading(false)
                    setPaketBlok(true)
                })
        } else {
            console.log('idNaloga is not defined')
        }
    }

    return (
        <KTCard>
            <ToastContainer/>
            <KTCardBody className='py-4'>
                {!paketiBlok && (
                    <div className='row'>
                        <h2 className='fw-bold'>Id Naloga</h2>
                        <div className='col-lg-3 col-xs-9'>
                            <input
                                type='text'
                                placeholder='Nalog ID'
                                className='form-control'
                                ref={inputRef} // Add this line
                                onChange={(e) => setidNaloga(e.target.value)}
                            />
                        </div>
                        <div className='col-lg-1 col-sm-3'>
                            <button
                                type='submit'
                                className='btn btn-primary'
                                onClick={showPaketi}
                                disabled={loading}
                            >
                                {!loading && 'Potvrdi'}
                                {loading && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                                )}
                            </button>
                        </div>
                    </div>
                )}
                {paketiBlok && <UpdateStatusComponent table={table} update={showPaketi}/>}
                <div className='separator mt-2 mb-4'></div>
                <div className='d-flex justify-content-between'>
                    <div data-kt-user-table-toolbar='base'>
                        <TableSearch onSearch={(text) => setGlobalFilter(text)}/>
                    </div>
                    <div data-kt-user-table-toolbar='base'>
                        <ExportButton table={table} filename={'thermoboard_paketi'}/>
                    </div>
                </div>

                {loading ? (
                    <LoadingComponent/>
                ) : (
                    <div className='table-responsive mt-3 '>
                        <table className='table table-bordered gy-5 no-footer'>
                            <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr
                                    key={headerGroup.id}
                                    className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                                >
                                    {headerGroup.headers.map((header) => (
                                        <th className='text-white' key={header.id}>
                        <span onClick={header.column.getToggleSortingHandler()}>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                            {/* @ts-ignore */}
                            {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                        </span>
                                            {header.column.getCanFilter() ? (
                                                <div>
                                                    {header.column.id === 'sirina' && (
                                                        <MinMaxFilter
                                                            minMaxValues={(min, max) => {
                                                                table.setColumnFilters([{
                                                                    id: 'sirina',
                                                                    value: [min, max]
                                                                }])
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            ) : null}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody className='text-gray-600'>
                            {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                                table.getRowModel().rows.map((row) => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => (
                                            <td
                                                key={cell.id}
                                                style={{
                                                    backgroundColor: getCellColor(cell, row),
                                                }}
                                            >
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={7}>
                                        <div
                                            className='d-flex text-center w-100 align-content-center justify-content-center'>
                                            No matching records found
                                        </div>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                )}
                <TablePagination
                    updatePage={(page, per_page) => setparams({...params, page: page, per_page: per_page})}
                    pagination={pagination}
                />
            </KTCardBody>
        </KTCard>
    )
}
export default ThermoBoardPaketi
