import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import React, {useEffect, useState} from 'react'
import {Modal, ModalHeader} from 'react-bootstrap'
import {getAllPaketiThermoboard} from '../../../../api/thermoboardNalozi'
import notifyToast from '../../../../components/Toast/Toast'
import LoadingComponent from '../../../../components/LoadingComponent/LoadingComponent'
import {ThermoBoardPaketiModel} from '../../../../models/ThermoBoardPaketi'
import {KTIcon} from '../../../../../_websoft/helpers'
import moment from "moment";


const ThermoBoardPakModal = ({isModalOpen, setisModalOpen, idNaloga}) => {
  const [data, setData] = useState<ThermoBoardPaketiModel[]>([])
  const [loading, setLoading] = useState(true)
  const [sorting, setSorting] = useState<SortingState>([])
  const [globalFilter, setGlobalFilter] = React.useState('')
  // Set cell color according to condtions.
  const getCellColor = (cell, row) => {
    // Color for Tr.roba
    if (cell.column.id === 'Status') {
      if (row.original.Status === 0) return '#FFFFFF'
      else if (row.original.Status === 1) return '#53F55A'
      else if (row.original.Status === 2) return '#FF4C44'
    }
    // Color default
    return '#FFFFFF'
  }

  const updatePage = () => {
    setLoading(true)
    getAllPaketiThermoboard({idNaloga: idNaloga})
      .then((response) => {
        const data = response.data['data']['data']
        setData(data)
      })
      .catch((error) => {
        console.error('Error: ', error)
        notifyToast(false, error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    updatePage()
  }, [idNaloga])

  const columns = [
    {
      id: 'Id',
      header: 'Paket',
      accessorKey: 'Id',
    },
    {
      id: 'Duzina',
      header: 'Duzina',
      accessorKey: 'Duzina',
    },

    {
      id: 'Sirina',
      header: 'Sirina',
      accessorKey: 'Sirina',
    },
    {
      id: 'Komada',
      header: 'Komada',
      accessorKey: 'Komada',
    },
    {
      id: 'Rezervisano za',
      header: 'Rezervisano za',
      accessorKey: 'Kupac',
    },
    {
      id: 'Proizvedeno',
      header: 'Proizvedeno',
      accessorKey: 'Proizvedeno', // This can be any unique string
      cell: ({row}) => {
        return row.original.Proizvedeno === 0 ? (
          <KTIcon iconName={'cross'} className='text-danger fs-2' />
        ) : (
          <KTIcon iconName={'check'} className='text-success fs-2' />
        )
      },
    },
    {
      id: 'Datum',
      header: 'Datum proizvodnje',
      accessorFn: (row) =>
          row.ProizvedenDatum && moment(row.ProizvedenDatum).isValid()
              ? moment(row.ProizvedenDatum).format('DD-MM-YYYY')
              : '',
    },
    {
      id: 'Isporuceno',
      header: 'Isporuceno',
      accessorKey: 'Isporuceno', // This can be any unique string
      cell: ({row}) => {
        return row.original.Isporuceno === 0 ? (
            <KTIcon iconName={'cross'} className='text-danger fs-2' />
        ) : (
            <KTIcon iconName={'check'} className='text-success fs-2' />
        )
      },
    },
    {
      id: 'Status',
      header: 'Status',
      cell: ({row}) => {
        if (row.original.Status === 0) return 'Isporučen'
        else if (row.original.Status === 1) return 'Raspoloživ'
        else if (row.original.Status === 2) return 'Rezervisan'
      },
    },
  ]
  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting: sorting,
      globalFilter: globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
  })
  return (
    <Modal show={isModalOpen} onHide={() => setisModalOpen(false)} className='modal-lg'>
      <ModalHeader closeButton={true} className='h2'>
        Thermoboard Paketi
      </ModalHeader>
      {loading ? (
        <LoadingComponent />
      ) : (
        <div className='table-responsive mt-3 '>
          <table className='table align-middle table-bordered gy-5 no-footer'>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                >
                  {headerGroup.headers.map((header) => (
                    <th
                      className='text-white'
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {/* @ts-ignore */}
                      {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className='text-gray-600'>
              {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} style={{backgroundColor: getCellColor(cell, row)}}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </Modal>
  )
}

export default ThermoBoardPakModal
