import React, { FC } from "react";
import {Page, Text, View, Document, StyleSheet, Image} from "@react-pdf/renderer";
import logo from '../../../../_websoft/assets/images/logo.jpg';
import PageTemplateLandscape from "../../paneli/components/PdfPageTemplate/PageTemplateLandscape";
import {splitDataForPagesSredstva} from "../../../models/Sredstva";
import moment from "moment/moment";
import PageTemplatePortrait from "../../paneli/components/PdfPageTemplate/PageTemplatePortrait";
// Define styles for the PDF document
const styles = StyleSheet.create({
    page: {
        fontFamily: "Roboto",
        paddingLeft:10,
        paddingRight:10,
    },
    tableRow: {
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 6,
        padding:2,
        paddingLeft:5,
    },
    tableRow2: {
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 10,
        padding:4,
        backgroundColor: "#d3d3d3",
    },
    tableColumnHeader1: {
        width: '350',
        textAlign: 'center',
    },
    tableColumnHeader2: {
        width: '50',
        textAlign: 'center',
    },
    tableColumnHeader3: {
        width: '70',
        textAlign: 'center',
    },
    header: {
        marginTop: 100,
        marginLeft:40,
        marginBottom: 10,
        fontSize: 18,
        textAlign: 'center',
    },
    subHeader: {
        marginLeft:40,
        fontSize: 10,
        textAlign: 'left',
    },
    tableColumn1: {
        width: '350',
        textAlign: 'center',
    },
    tableColumn2: {
        width: '50',
        textAlign: 'center',
    },
    tableColumn3: {
        width: '70',
        textAlign: 'center',
    },
    tabela: {
        marginTop: 10,
        border: 0.5,
        borderColor: '#000000',
        borderStyle: 'solid',
    },
    lastPageRow: {
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 10,
        padding: 5,
        marginTop: 15,
    },
    lastPageColumn1: {
        width: '50%',
        textAlign: 'center',
    },
    lastPageColumn2: {
        width: '50%',
        textAlign: 'center',
    },
    lastPageColumn3: {
        width: '33.33%',
        textAlign: 'center',
    },
    subText: {
        fontSize: 8, // Smaller font size for subtitle
        marginTop: 20, // Add spacing
    },
    subText2: {
        fontSize: 8, // Smaller font size for subtitle
        marginTop: 2, // Add spacing
    }
});


const PlanPreventivnogOdrzavanja: FC<{ data: any[] }> = ({ data }) => {
    const itemsPerPage = 32; // Adjust based on your content size
    const pagesData = splitDataForPagesSredstva(data, itemsPerPage);
    return (
        <Document style={styles.page}>
            {pagesData.map((pageData, index) => (
                <PageTemplateLandscape key={index}>
                    {/* Show header only on the first page */}
                    {index === 0 && (
                        <>
                            <Text style={styles.header}>Plan preventivnog održavanja</Text>
                            <Text style={styles.subHeader}>Broj: </Text>
                            <Text style={styles.subHeader}>Datum: </Text>
                        </>
                    )}

                    {/* Apply marginTop only from the second page onward */}
                    <View style={[styles.tabela, index > 0 ? { marginTop: 100 } : {}]}>
                        <View style={styles.tableRow2}>
                            <Text style={styles.tableColumnHeader1}>Naziv </Text>
                            <Text style={styles.tableColumnHeader2}>Inv. broj</Text>
                            <Text style={styles.tableColumnHeader3}>Atestiranje</Text>
                            <Text style={{ width: 100, textAlign: "center"}}>Čišćenje</Text>
                            <Text style={styles.tableColumnHeader3}>Podmazivanje</Text>
                            <Text style={styles.tableColumnHeader2}>Remont</Text>
                            <Text style={{ width: 130, textAlign: "center"}}>Uposlenik</Text>
                        </View>

                        {pageData.map((item, itemIndex) => (
                            <View key={itemIndex} style={styles.tableRow}>
                                <Text style={styles.tableColumn1}>{item.SredstvoNaziv || ''}</Text>
                                <Text style={styles.tableColumn2}>{item.SredstvoInvBroj || ''}</Text>
                                <Text style={styles.tableColumn3}>{item.SredstvoPrevAtest || ''}</Text>
                                <Text style={{ width: 100, textAlign: "center"}}>{item.SredstvoMarka || ''}</Text>
                                <Text style={styles.tableColumn3}>{item.SredstvoPrevPodmazivanje || ''}</Text>
                                <Text style={styles.tableColumn2}>{item.SredstvoPrevRemont || ''}</Text>
                                <Text style={{ width: 130, textAlign: "center"}}>{item.zaduzio_uposlenik?.UposlenikImePrezime || ''}</Text>
                            </View>
                        ))}
                    </View>
                    {/* Add extra rows only on the last page */}
                    {index === pagesData.length - 1 && (
                        <>
                            <Text style={{ fontSize:10, marginLeft:20}}>Legenda</Text>
                            <Text style={{ fontSize:8, marginLeft:20}}>SD: Svaki dan</Text>
                            <Text style={{ fontSize:8, marginLeft:20}}>1S: Jednom sedmično</Text>
                            <Text style={{ fontSize:8, marginLeft:20}}>PP: Po potrebi</Text>
                            <Text style={{ fontSize:8, marginLeft:20}}>3G: Jednom Godišnje</Text>
                            {/* Row with 2 columns */}
                            <View style={styles.lastPageRow}>
                                {/* First Column (Izradio) */}
                                <View style={styles.lastPageColumn1}>
                                    <Text>Izradio: Ibrahim Balta</Text>
                                    <Text style={styles.subText}>/Voditelj procesa održavanja/</Text>

                                </View>
                                {/* Second Column (Ovjerio) */}
                                <View style={styles.lastPageColumn2}>
                                    <Text>Ovjerio: Senahid Lemeš</Text>
                                    <Text style={styles.subText}>/Rukovodilac logistike/</Text>
                                </View>
                            </View>
                            {/* Row with 3 columns */}
                            <View style={styles.lastPageRow}>
                                <Text style={styles.lastPageColumn3}>OB-713/01</Text>
                                <Text style={styles.lastPageColumn3}>Izdanje 2: 30.12.2022.</Text>
                                <Text style={styles.lastPageColumn3}>Rev: 1</Text>
                            </View>
                        </>
                    )}
                </PageTemplateLandscape>
            ))}
        </Document>
    );
};
export default PlanPreventivnogOdrzavanja;
