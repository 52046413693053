import {PaketLezarina} from "./PaketLezarina";
import {UposlenikModel} from "./UposlenikModel";

export interface Sredstva {
  SredstvoId: string
  SredstvoInvBroj?: string
  SredstvoNaziv?: string
  SredstvoPuniNaziv?: string
  SredstvoKategorija?: string
  SredstvoProizvodjac?: string
  SredstvoMarka?: string
  SredstvoGodProizv?: string
  SredstvoTvorBr?: string
  SredstvoKarakteristika?: string
  SredstvoLokacija?: string
  SredstvoOdjel?: string
  SredstvoZaduzen?: string
  SredstvoServisiranje?: string
  SredstvoOdrzavanje?: string
  SredstvoAtest?: string
  SredstvoUputstvo?: string
  SredstvoSeVodi?: string
  SredstvoSlika?: string
  SredstvoSlika2?: string
  SredstvoStatus?: string
  SredstvoPrevAtest?: string
  SredstvoUpotrDozvola?: string
  SredstvoRegistrator?: string
  SredstvoPrevPodmazivanje?: string
  SredstvoPrevRemont?: string
  SredstvoTehDok?: string
  SredstvoDokZaduzio?: string
  SredstvoDokZadDatum?: string
  SredstvoNapomene?: string
  SredstvoPresetorijum?: string
  Kreirano?: string
  Izmjenjeno?: string
  zaduzio_uposlenik:UposlenikModel

}

export interface SredstvaIzvjestaj {
  SifraArtikla?: string
  NazivArtikla?: string
  Norma?: string
  PotrebnoVrijeme?: string
  Kolicina?: string
}
export interface Option {
  value: string
  label: string
}

export interface ParamsModelSredstva {
  per_page: number
  page: number
  active?: number
  kategorija?: number
  status?: number
  uposlenik?: number
}

export const splitDataForPagesSredstva = (data: any[], itemsPerPage: number): any[][] => {
  const pages: any[][] = []; // Explicitly define the type as an array of arrays

  for (let i = 0; i < data.length; i += itemsPerPage) {
    let pageData: any[] = data.slice(i, i + itemsPerPage);

    // Fill with empty objects to match full page length
    while (pageData.length < itemsPerPage) {
      pageData.push({}); // Push an empty object to maintain structure
    }

    pages.push(pageData);
  }
  return pages;
};


