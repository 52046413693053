/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import {
    flexRender,
    useReactTable,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    getFilteredRowModel,
} from '@tanstack/react-table'
import TablePagination from '../../components/TablePagination/TablePagination'
import notifyToast from '../../components/Toast/Toast'
import {ToastContainer} from 'react-toastify'
import {KTCard, KTCardBody, KTIcon, KTSVG} from '../../../_websoft/helpers'
import TableSearch from '../../components/TableSearch/TableSearch'
import ExportButton from '../../components/TableExportToExcel/ExportButton'
import {Link} from 'react-router-dom'
import {TableActionButton} from '../../components/TableActionBtn/TableActionButton'
import {useNavigate} from 'react-router-dom'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import {Sredstva, ParamsModelSredstva} from '../../models/Sredstva'
import {deleteSredstvo, getAllSredstva} from '../../api/sredstva'
import {PaginationModel} from '../../models/PaginationModel'
import KategorijaSredstvaDropdown from '../../components/KategorijaSredstvaDropdown/KategorijaSredstvaDropdown'
import RolnePdf from "../../components/QrCodeGenerator/RolnePdf";
import {usePDF} from "@react-pdf/renderer";
import SredstvaQrCodePdf from "../../components/QrCodeGenerator/SredstvaQRCodePdf";
import {ParamsModelKreirajQRcode} from "../../models/KreirajQRcodeModel";
import DosjeiPdf from "../uposlenici/widgets/DosjeiPdf";
import UposlenikDropdown from "../../components/UposlenikDropdown/UposlenikDropdown";
import OdjeliDropdown from "../../components/OdjeliDropdown/OdjeliDropdown";
import StatusSredstvaDropdown from "../../components/StatusDropdown/StatusSredstvaDropdown";

const SredstvaLista: React.FC = () => {
    const [sredstva, setSredstva] = useState<Sredstva[]>([])
    const [loading, setLoading] = useState(true)
    const [pagination, setpagination] = useState<PaginationModel>({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    })
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [filter, setfilter] = useState('')
    const [params, setparams] = useState<ParamsModelSredstva>({per_page: 10, page: 1, active: 1})

    const [sorting, setSorting] = useState<SortingState>([])
    const [globalFilter, setGlobalFilter] = React.useState('')

    const updatePage = () => {
        setLoading(true)
        getAllSredstva(params)
            .then((response) => {
                setSredstva(response.data['data'])
                const totalPage = Math.floor(response.data['total'] / response.data['per_page'] + 1)
                setpagination({
                    curPage: response.data['current_page'],
                    totalPage: totalPage,
                    perPage: params.per_page,
                })
            })
            .catch((error) => {
                console.error('Error: ', error)
                notifyToast(false, error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const [instance, updateInstance] = usePDF({})

    // Open Pdf when intance link is generated.
    useEffect(() => {
        if (!instance.loading && instance.url) {
            window.open(instance.url, '_blank')?.focus()
        }
    }, [instance])

    const generateQrCodeSredstvo = (data) => {
        updateInstance(<SredstvaQrCodePdf qrData={data}/>)
    }

    const StatusCell = ({row}) => {
        return (
            row.original.status && (
                <div
                    className='badge text-white'
                    style={{
                        backgroundColor: row.original.status.Boja,
                        padding: '15px',
                    }}
                >
                    {row.original.status.Naziv}
                </div>
            )
        )
    }

    useEffect(() => {
        updatePage()
    }, [params])

    useEffect(() => {
        const {id} = sorting[0] ?? ''
        sredstva.sort((a, b) => a[id] - b[id])
    }, [sorting])

    const columns = [
        {
            id: 'Id',
            header: 'Id',
            accessorKey: 'SredstvoId',
        },
        {
            id: 'Inv broj',
            header: 'Inv broj',
            accessorKey: 'SredstvoInvBroj',
        },
        {
            id: 'Naziv',
            header: 'Naziv',
            accessorKey: 'SredstvoNaziv',
        },
        {
            id: 'Odjel',
            header: 'Odjel',
            accessorKey: 'odjel.OdjelNaziv',
        },
        {
            id: 'Kategorija',
            header: 'Kategorija',
            accessorKey: 'kategorija.KatNaziv',
        },
        {
            id: 'Zaduzio',
            header: 'Zaduzio',
            accessorKey: 'zaduzio_uposlenik.UposlenikImePrezime',
        },
        {
            id: 'Status',
            header: 'Status',
            accessorKey: 'Status',
            cell: StatusCell,
        },
        {
            id: 'Dok.',
            header: 'Dok.',
            accessorFn: (row) => `${row.SredstvoTehDok + row.SredstvoRegistrator}`,
        },
        {
            id: 'Vodi se',
            header: 'Vodi se',
            accessorKey: 'SredstvoSeVodi',
        },
        {
            id: 'qrCode',
            header: () => <i className="bi bi-qr-code fs-2" style={{cursor: 'pointer', color: '#FFF'}}></i>,
            accessorKey: 'someKey', // replace 'someKey' with the relevant data key or leave it if not needed
            cell: ({row}) => (
                <i
                    className="bi bi-qr-code fs-2"
                    style={{cursor: 'pointer', color: '#000'}}
                    onClick={() => generateQrCodeSredstvo(row.original)}
                ></i>
            )
        },
        {
            header: 'Akcija',
            accessorKey: 'Akcija',
            cell: ({row}) => (
                <TableActionButton
                    onDelete={() => onDelete(row.original.SredstvoId)}
                    onEdit={() => {
                        onEdit(row.original.SredstvoId)
                    }}
                />
            ),
        },
    ]
    const table = useReactTable({
        data: sredstva,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting: sorting,
            globalFilter: globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        getFilteredRowModel: getFilteredRowModel(),
        onSortingChange: setSorting,
    })
    const onDelete = (id) => {
        deleteSredstvo(id)
            .then((response) => {
                notifyToast(true, response.message)
                setparams({per_page: pagination.perPage, page: pagination.curPage})
            })
            .catch((error) => {
                notifyToast(false, error.data['message'])
            })
    }
    const navigate = useNavigate()

    const onEdit = (rowData) => {
        navigate(`/sredstva/izmjena/${rowData}`)
    }

    return (
        <KTCard>
            <ToastContainer/>
            <KTCardBody className='py-4'>
                {/* begin:Filters */}
                <div className='accordion accordion-icon-toggle' id='kt_accordion_2'>
                    <div className='mb-5'>
                        <div
                            className='accordion-header py-3 d-flex '
                            data-bs-toggle='collapse'
                            data-bs-target='#kt_accordion_2_item_2'
                            aria-expanded={true}
                        >
              <span className='accordion-icon'>
                <KTSVG
                    className='svg-icon svg-icon-4'
                    path='/media/icons/duotune/arrows/arr064.svg'
                />
              </span>
                            <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>FILTERI</h3>
                        </div>
                        <div
                            id='kt_accordion_2_item_2'
                            className='collapse fs-6 ps-10 show'
                            data-bs-parent='#kt_accordion_2'
                        >
                            <div className='d-flex justify-content-between py-5'>
                                <div className='col-3 fv-row'>
                                    <KategorijaSredstvaDropdown
                                        onChange={(name, value) => setparams({...params, kategorija: value})}
                                    />
                                </div>
                                <div className='col-3 fv-row'>
                                    <UposlenikDropdown
                                        onChange={(name, value) => setparams({...params, uposlenik: value})}
                                    />
                                </div>
                                <div className='col-3 fv-row'>
                                    <StatusSredstvaDropdown
                                        onChange={(name, value) => setparams({...params, status: value})}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end:Filters */}
                <div className='separator mb-5'></div>
                <div className='d-flex justify-content-between'>
                    <div data-kt-user-table-toolbar='base'>
                        <TableSearch onSearch={(text) => setGlobalFilter(text)}/>
                    </div>
                    <div data-kt-user-table-toolbar='base'>
                        <Link to='/sredstva/dodaj'>
                            <button type='button' className='btn btn-primary'>
                                <KTIcon iconName='plus' className='fs-2'/>
                                Dodaj sredstvo
                            </button>
                        </Link>
                        <ExportButton table={table} filename={'sredstva_lista'}/>
                    </div>
                </div>
                {loading ? (
                    <LoadingComponent/>
                ) : (
                    <div className='table-responsive mt-3 '>
                        <table className='table align-middle table-bordered gy-5 no-footer'>
                            <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr
                                    key={headerGroup.id}
                                    className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                                >
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            className='text-white'
                                            key={header.id}
                                            onClick={header.column.getToggleSortingHandler()}
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                            {/* @ts-ignore */}
                                            {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody className='text-gray-600'>
                            {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                                table.getRowModel().rows.map((row) => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => (
                                            <td key={cell.id}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={7}>
                                        <div
                                            className='d-flex text-center w-100 align-content-center justify-content-center'>
                                            No matching records found
                                        </div>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                )}
                <TablePagination
                    updatePage={(page, per_page) => setparams({...params, page: page, per_page: per_page})}
                    pagination={pagination}
                />
            </KTCardBody>
        </KTCard>
    )
}
export default SredstvaLista
