import {KTCard, KTCardBody} from "../../../_websoft/helpers";
import {pdf} from "@react-pdf/renderer";
import {saveAs} from "file-saver";
import {ToastContainer} from "react-toastify";
import TitleComponent from "../../components/TitleComponent/TitleComponent";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import PlanPreventivnogOdrzavanja from "./Izvjestaji/PlanPreventivnogOdrzavanja";
import {getPlanPreventivnogOdrzavanja, getPopisRaspoloziveOpreme} from "../../api/sredstva";
import PopisRaspoloziveOpreme from "./Izvjestaji/PopisRaspoloziveOpreme";
import PopisRaspolozivihDIjelova from "./Izvjestaji/PopisRaspolozivihDijelova";

const SredstvaIzvjestaji: React.FC = () => {
    const [loading, setLoading] = useState(true);

    // Individual button states
    const [buttonText, setButtonText] = useState("Plan preventivnog održavanja");
    const [buttonText2, setButtonText2] = useState("Popis raspoložive opreme");
    const [buttonText3, setButtonText3] = useState("Popis raspoloživih rezervnih dijelova");

    const [isProcessing1, setIsProcessing1] = useState(false);
    const [isProcessing2, setIsProcessing2] = useState(false);
    const [isProcessing3, setIsProcessing3] = useState(false);

    const generatePdf = async () => {
        if (isProcessing1) return;
        setIsProcessing1(true);
        setButtonText("Sačekaj... Kreiranje dokumenta...");

        try {
            const fetchedData = await getPlanPreventivnogOdrzavanja();

            if (!fetchedData || fetchedData.length === 0) {
                alert("No data available to generate PDF.");
                setButtonText("Plan preventivnog održavanja");
                setIsProcessing1(false);
                return;
            }

            const doc = <PlanPreventivnogOdrzavanja data={fetchedData} />;
            const blob = await pdf(doc).toBlob();
            saveAs(blob, "PlanPreventivnogOdrzavanja.pdf");
        } catch (error) {
            console.error("Error generating PDF:", error);
            alert("Failed to generate PDF.");
        } finally {
            setIsProcessing1(false);
            setButtonText("Plan preventivnog održavanja");
        }
    };

    const generatePdf2 = async () => {
        if (isProcessing2) return;
        setIsProcessing2(true);
        setButtonText2("Sačekaj... Kreiranje dokumenta...");

        try {
            const fetchedData = await getPopisRaspoloziveOpreme();

            if (!fetchedData || fetchedData.length === 0) {
                alert("No data available to generate PDF.");
                setButtonText2("Popis raspoložive opreme");
                setIsProcessing2(false);
                return;
            }

            const doc = <PopisRaspoloziveOpreme data={fetchedData} />;
            const blob = await pdf(doc).toBlob();
            saveAs(blob, "PopisRaspoloziveOpreme.pdf");
        } catch (error) {
            console.error("Error generating PDF:", error);
            alert("Failed to generate PDF.");
        } finally {
            setIsProcessing2(false);
            setButtonText2("Popis raspoložive opreme");
        }
    };

    const generatePdf3 = async () => {
        if (isProcessing3) return;
        setIsProcessing3(true);
        setButtonText3("Sačekaj... Kreiranje dokumenta...");

        try {
            const fetchedData = await getPopisRaspoloziveOpreme();

            if (!fetchedData || fetchedData.length === 0) {
                alert("No data available to generate PDF.");
                setButtonText3("Popis raspoloživih rezervnih dijelova");
                setIsProcessing3(false);
                return;
            }

            const doc = <PopisRaspolozivihDIjelova data={fetchedData} />;
            const blob = await pdf(doc).toBlob();
            saveAs(blob, "PopisRezervnihDijelova.pdf");
        } catch (error) {
            console.error("Error generating PDF:", error);
            alert("Failed to generate PDF.");
        } finally {
            setIsProcessing3(false);
            setButtonText3("Popis raspoloživih rezervnih dijelova");
        }
    };

    return (
        <KTCard>
            <ToastContainer/>
            <KTCardBody className='py-5'>
                <TitleComponent name='Izvještaji'/>
                <div className='row py-5'>
                    <div className="col-lg-4 col-md-6">
                        <button
                            className="btn btn-primary d-flex align-items-center gap-2 p-5"
                            onClick={generatePdf}
                            disabled={isProcessing1}
                        >
                            <i className="bi bi-file-earmark-pdf fs-2"></i>
                            {buttonText}
                        </button>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <button
                            className="btn btn-primary d-flex align-items-center gap-2 p-5"
                            onClick={generatePdf2}
                            disabled={isProcessing2}
                        >
                            <i className="bi bi-file-earmark-pdf fs-2"></i>
                            {buttonText2}
                        </button>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <button
                            className="btn btn-primary d-flex align-items-center gap-2 p-5"
                            onClick={generatePdf3}
                            disabled={isProcessing3}
                        >
                            <i className="bi bi-file-earmark-pdf fs-2"></i>
                            {buttonText3}
                        </button>
                    </div>
                </div>
                <div className='separator mt-4 mb-4'></div>
                <div className='row py-5'>
                    <div className='col-lg-4 col-md-6'>
                        <Link to='../izvjestaji/ucinak-sredstva'>
                            <button className='btn btn-dark p-5'>
                                <i className="bi bi-box-arrow-in-up-right fs-2"></i>
                                Učinak Sredstva
                            </button>
                        </Link>
                    </div>
                </div>
            </KTCardBody>
        </KTCard>
    );
};

export default SredstvaIzvjestaji;

