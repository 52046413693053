import React from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_websoft/layout/core'
import TerminaliDashboard from './TerminaliDashboard'
import RepromaterijaliLista from '../repromaterijali/RepromaterijaliLista'
import Repromaterijali from './repromaterijali/Repromaterijali'
import RolneUDolasku from '../repromaterijali/RolneUDolasku'
import PrometDetails from '../repromaterijali/PrometDetails'
import PrometRolneTerminal from './repromaterijali/PrometRolneTerminal'
import PrometRolneDirektnaProdaja from './repromaterijali/PrometRolneDirektnaProdaja'
import Galanterija from './galanterija/Galanterija'
import OtvorenNalog from './galanterija/OtvorenNalog'
import TerminaliArtikli from './artikli/TerminaliArtikli'
import PrometArtiklaDetails from '../artikli/PrometArtiklaDetails'
import AktivnePalete from '../artikli/AktivnePalete'
import ArtikliLista from '../artikli/ArtikliLista'
import Profilacija from './profilacija/Profilacija'
import PrijavaKvaraTerminal from './prijava-kvara/PrijavaKvaraTerminal'
import InterencijeOdrzavanjaLista from '../sredstva/IntervencijeOdrzavanjaLista'
import MojeIntervencije from './prijava-kvara/MojeIntervencije'
import Termopaneli from './termopaneli/Termopaneli'
import NalogStart from './termopaneli/NalogStart'
import AktivniNalozi from './termopaneli/AktivniNalozi'
import AktivniNaloziDetails from './termopaneli/AktivniNaloziDetails'
import TerminaliRasjecanje from './rasjecanje/TerminaliRasjecanje'
import Rasjecanje from './rasjecanje/Rasjecanje'
import IsporuciPaket from './termopaneli/IsporuciPaket'
import ThermoBoardPaket from './termopaneli/ThermoBoardPaket'
import IzmjenaLokacije from './repromaterijali/IzmjenaLokacije'
import PrijaviGresku from './repromaterijali/PrijaviGresku'
import TermopaneliDnevniIzvjestaj from './termopaneli/TermopaneliDnevniIzvjestaj'
import PrijavaKvaraDetails from '../sredstva/PrijavaKvaraDetails'
import ZahtjevZaNabavuDetails from '../sredstva/ZahtjevZaNabavuDetails'
import ThermopaneliPrometRolne from './termopaneli/ThermopaneliPrometRolne'
import RadniNaloziDashboard from './radniNalozi/RadniNaloziDashboard'
import SpremiNalog from './radniNalozi/SpremiNalog'
import PrijavaKvara from './PrijavaKvara'
import DodajIntervenciju from './prijava-kvara/DodajIntervenciju'
import IsporuciPaketDrugaKlasa from './termopaneli/IsporuciPaketDrugaKlasa'
import GlavnaKnjigaDetails from '../paneli/GlavnaKnjigaDetails'
import IsporuciNalog from './radniNalozi/IsporuciNalog'
import RadniNalogStart from './radniNalogStart/RadniNalogStart'
import RadniNalogKraj from './radniNalogKraj/RadniNalogKraj'
import Proizodnja from "../paneli/Proizodnja";
import KarticaRolne from './repromaterijali/KarticaRolne'
import PrometTrake from './repromaterijali/PrometTrake'
import PrometTraka from "./terminali-components/PrometTraka";
import PrijedloziZaPoboljsanjeDetails from "../uposlenici/PrijedloziZaPoboljsanjeDetails";
import RadUProizvodnji from "../uposlenici/Izvjestaj/components/RadUProizvodnji";
import InventuraRolne from "./repromaterijali/InventuraRolne";
import InventuraTrake from "./repromaterijali/InventuraTrake";
import Viljuskari from "./viljuskari/Viljuskari";
import ViljuskariZapisiDetails from "./viljuskari/ViljuskariZapisiDetails";
import ViljuskariDetails from "./viljuskari/ViljuskariDetails";
import PrometRepromaterijalaPaneli from "./termopaneli/PaneliPrometRepromaterijala";
import PaneliPrometRolna from "./termopaneli/PaneliPrometRolna";
import PaneliPrometTraka from "./termopaneli/PaneliPrometTraka";
import IzmjenaStatusaILokacijeTrake from "./repromaterijali/IzmjenaStatusaILokacijeTrake";

const TerminaliBreadCrumbs: Array<PageLink> = [
  {
    title: 'Terminali',
    path: '/terminali/pocetna',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const TerminaliRoutes: React.FC = () => {
    return (
        <Routes>
            <Route
                element={
                    <>
                        <Outlet/>
                    </>
                }
            >
                <Route
                    path='pocetna'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Terminali Dashboard</PageTitle>
                            <TerminaliDashboard/>
                        </>
                    }
                />
                <Route
                    path='repromaterijali'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Terminali Dashboard</PageTitle>
                            <Repromaterijali/>
                        </>
                    }
                />
                <Route
                    path='repromaterijali/lista'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Terminali Dashboard</PageTitle>
                            <RepromaterijaliLista/>
                        </>
                    }
                />
                <Route
                    path='repromaterijali/rolne-u-dolasku'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Terminali Dashboard</PageTitle>
                            <RolneUDolasku/>
                        </>
                    }
                />
                <Route
                    path='repromaterijali/promet'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Terminali Dashboard</PageTitle>
                            <PrometRolneTerminal />
                        </>
                    }
                />
                <Route
                    path='repromaterijali/promet-proizvodnja'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Terminali Dashboard</PageTitle>
                            <PrometDetails />
                        </>
                    }
                />
                <Route
                    path='repromaterijali/promet-prodaja'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Terminali Dashboard</PageTitle>
                            <PrometRolneDirektnaProdaja />
                        </>
                    }
                />
                <Route
                    path='repromaterijali/promet-trake'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Terminali Dashboard</PageTitle>
                            <PrometTrake />
                        </>
                    }
                />
                <Route
                    path='repromaterijali/izmjena-trake'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Terminali Dashboard</PageTitle>
                            <IzmjenaStatusaILokacijeTrake />
                        </>
                    }
                />
                <Route
                    path='repromaterijali/izmjena-lokacije'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Izmjena Lokacije</PageTitle>
                            <IzmjenaLokacije/>
                        </>
                    }
                />
                <Route
                    path='repromaterijali/prijavi-gresku'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Prijavi grešku</PageTitle>
                            <PrijaviGresku/>
                        </>
                    }
                />
                <Route
                    path='repromaterijali/kartica-rolne'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Kartica rolne</PageTitle>
                            <KarticaRolne/>
                        </>
                    }
                />
                <Route
                    path='repromaterijali/inventura-rolni'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Inventura rolne</PageTitle>
                            <InventuraRolne/>
                        </>
                    }
                />
                <Route
                    path='repromaterijali/inventura-traka'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Inventura rolne</PageTitle>
                            <InventuraTrake/>
                        </>
                    }
                />
                <Route
                    path='termopaneli'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Terminali Dashboard</PageTitle>
                            <Termopaneli/>
                        </>
                    }
                />
                <Route
                    path='termopaneli/dnevni-izvjestaj'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Termopaneli Dnevni Izvještaj</PageTitle>
                            <TermopaneliDnevniIzvjestaj/>
                        </>
                    }
                />
                <Route
                    path='termopaneli/glavna-knjiga-dodaj'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Termopaneli Glavna Knjiga</PageTitle>
                            <GlavnaKnjigaDetails/>
                        </>
                    }
                />
                <Route
                    path='termopaneli/isporuci-paket'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Isporuči paket</PageTitle>
                            <IsporuciPaket/>
                        </>
                    }
                />
                <Route
                    path='termopaneli/isporuci-paket-druga-klasa'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Isporuči paket 2 klasa</PageTitle>
                            <IsporuciPaketDrugaKlasa/>
                        </>
                    }
                />
                <Route
                    path='termopaneli/thermoboard-paketi'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Thermoboard paket</PageTitle>
                            <ThermoBoardPaket/>
                        </>
                    }
                />
                <Route
                    path='termopaneli/nalog-start'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Nalog Start</PageTitle>
                            <NalogStart/>
                        </>
                    }
                />
                <Route
                    path='termopaneli/lista'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Aktivni nalozi panela</PageTitle>
                            <Proizodnja/>
                        </>
                    }
                />
                <Route
                    path='termopaneli/aktivni-nalozi'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Aktivni nalozi panela</PageTitle>
                            <AktivniNalozi/>
                        </>
                    }
                />
                <Route
                    path='termopaneli/aktivni-nalozi/:id'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Aktivni nalozi panela</PageTitle>
                            <AktivniNaloziDetails/>
                        </>
                    }
                />
                <Route
                    path='termopaneli/promet-rolne'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Promet repromaterijala</PageTitle>
                            {/*<ThermopaneliPrometRolne/>*/}
                            <PrometRepromaterijalaPaneli />
                        </>
                    }
                />
                <Route
                    path='termopaneli/promet/rolna/:id'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Promet repromaterijala / rolna</PageTitle>
                            {/*<ThermopaneliPrometRolne/>*/}
                            <PaneliPrometRolna />
                        </>
                    }
                />
                <Route
                    path='termopaneli/promet/traka/:id'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Promet repromaterijala / traka</PageTitle>
                            {/*<ThermopaneliPrometRolne/>*/}
                            <PaneliPrometTraka />
                        </>
                    }
                />
                <Route
                    path='galanterija/pocetna'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Terminali Dashboard</PageTitle>
                            <Galanterija/>
                        </>
                    }
                />
                <Route
                    path='galanterija/otvoren-nalog'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Terminali Dashboard</PageTitle>
                            <OtvorenNalog/>
                        </>
                    }
                />
                <Route
                    path='artikal/pocetna'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Terminali Dashboard</PageTitle>
                            <TerminaliArtikli/>
                        </>
                    }
                />
                <Route
                    path='artikal/lista'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Lista artikala</PageTitle>
                            <ArtikliLista/>
                        </>
                    }
                />
                <Route
                    path='artikal/palete'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Palete artikala</PageTitle>
                            <AktivnePalete/>
                        </>
                    }
                />
                <Route
                    path='artikal/promet'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Izlaz artikla</PageTitle>
                            <PrometArtiklaDetails/>
                        </>
                    }
                />
                <Route
                    path='radni-nalog-start/pocetna'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Radni Nalog Start</PageTitle>
                            <RadniNalogStart/>
                        </>
                    }
                />
                <Route
                    path='radni-nalizi/:id'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Radni Nalog Kraj</PageTitle>
                            <RadniNalogKraj/>
                        </>
                    }
                />
                <Route
                    path='profilacija/pocetna'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Profilacija</PageTitle>
                            <Profilacija/>
                        </>
                    }
                />
                <Route
                    path='prijava-kvara/pocetna'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Prijava kvara</PageTitle>
                            <PrijavaKvaraTerminal/>
                        </>
                    }
                />
                <Route
                    path='prijava-kvara/dodaj'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Prijava kvara</PageTitle>
                            <PrijavaKvaraDetails/>
                        </>
                    }
                />
                <Route
                    path='prijava-kvara/prijave'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Prijava kvara</PageTitle>
                            <PrijavaKvara/>
                        </>
                    }
                />
                <Route
                    path='prijava-kvara/intervencije'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Intervencije</PageTitle>
                            <InterencijeOdrzavanjaLista/>
                        </>
                    }
                />
                <Route
                    path='prijava-kvara/moje-intervencije'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Moje intervencije</PageTitle>
                            <MojeIntervencije/>
                        </>
                    }
                />
                <Route
                    path='prijava-kvara/dodaj-intervenciju/:id'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Moje intervencije</PageTitle>
                            <DodajIntervenciju/>
                        </>
                    }
                />
                <Route
                    path='prijedlozi-za-poboljsanje/dodaj'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Prijedlozi za poboljšanje</PageTitle>
                            <PrijedloziZaPoboljsanjeDetails/>
                        </>
                    }
                />
                <Route
                    path='dashboard-rasjecanje'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Dashboard</PageTitle>
                            <TerminaliRasjecanje/>
                        </>
                    }
                />
                <Route
                    path='dashboard-rasjecanje/rasjecanje/:odjelId'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Rasjecanje</PageTitle>
                            <Rasjecanje/>
                        </>
                    }
                />
                <Route
                    path='dashboard-rasjecanje/odmotavanje/:odjelId'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Odmotavanje</PageTitle>
                            <Rasjecanje/>
                        </>
                    }
                />
                <Route
                    path='zahtjev-za-nabavu/dodaj'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Odmotavanje</PageTitle>
                            <ZahtjevZaNabavuDetails/>
                        </>
                    }
                />
                <Route
                    path='radni-nalozi/pocetna'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Radni Nalozi</PageTitle>
                            <RadniNaloziDashboard/>
                        </>
                    }
                />
                <Route
                    path='radni-nalozi/spremi-nalog'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Spremi Nalog</PageTitle>
                            <SpremiNalog/>
                        </>
                    }
                />
                <Route
                    path='radni-nalozi/isporuci-nalog'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Isporuci Nalog</PageTitle>
                            <IsporuciNalog/>
                        </>
                    }
                />
                <Route
                    path='uposlenici/izvjestaj'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Izještaj Jednostruku Limovi I Galanterija</PageTitle>
                            <RadUProizvodnji/>
                        </>
                    }
                />
                <Route
                    path='viljuskari/pocetna'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Viljuškari</PageTitle>
                            <Viljuskari/>
                        </>
                    }
                />
                <Route
                    path='viljuskari/dodaj'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Viljuškari Dodaj zapis</PageTitle>
                            <ViljuskariDetails/>
                        </>
                    }
                />
                <Route
                    path='viljuskari/dodaj/:id'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Viljuškari Dodaj zapis</PageTitle>
                            <ViljuskariDetails/>
                        </>
                    }
                />
                <Route
                    path='viljuskari/zapisi/dodaj'
                    element={
                        <>
                            <PageTitle breadcrumbs={TerminaliBreadCrumbs}>Viljuškari Dodaj zapis</PageTitle>
                            <ViljuskariZapisiDetails/>
                        </>
                    }
                />

            </Route>
        </Routes>
    );
}
export default TerminaliRoutes
