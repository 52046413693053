import React, {useState} from 'react'
import {KTCard, KTCardBody} from '../../../../_websoft/helpers'
import QrCodeScanner from '../../../components/QrCodeScanner/QrCodeScanner'
import clsx from 'clsx'
import {getKarticaRolne, updateQRkodRolna} from '../../../api/karticaRolne'
import notifyToast from '../../../components/Toast/Toast'
import {karticaRolneModel} from '../../../models/KarticaRolneModel'
import LoadingComponent from '../../../components/LoadingComponent/LoadingComponent'
import {flexRender, getCoreRowModel, useReactTable} from '@tanstack/react-table'
import {ToastContainer} from 'react-toastify'
import moments from "moment/moment";

const KarticaRolne = () => {
    const [rolnaId, setrolnaId] = useState<string>()
    const [loading, setLoading] = useState(false)
    const [table1Data, settable1Data] = useState<karticaRolneModel['rolnaInfo']>()
    const [table2Data, settable2Data] = useState<karticaRolneModel['promet']>([])
    const [table3Data, settable3Data] = useState<karticaRolneModel['lokacije']>([])

    // Get data for Table
    const getTableData = () => {
        setLoading(true)
        getKarticaRolne({rolnaId})
            .then((response) => {
                settable1Data(response.data['rolnaInfo'])
                settable2Data(response.data['promet'])
                settable3Data(response.data['lokacije'])
            })
            .catch((error) => notifyToast(false, error.message))
            .finally(() => setLoading(false))
    }

    //   Update Qr kod button
    const updateQrKod = () => {
        setLoading(true)
        updateQRkodRolna({rolnaId})
            .then((response) => {
                notifyToast(true, response.message)
            })
            .catch((error) => {
                notifyToast(false, error.message)
            })
            .finally(() => setLoading(false))
    }
    const columns2 = [
        {
            id: 'Id',
            header: 'Id',
            accessorKey: 'Id',
        },
        {
            id: 'Datum',
            header: 'Datum',
            accessorKey: 'Datum',
        },
        {
            id: 'Nalog',
            header: 'Nalog',
            accessorKey: 'BrojDokumentaIzlaza',
        },
        {
            id: 'Pocetno',
            header: 'Pocetno',
            accessorKey: 'Pocetno',
        },
        {
            id: 'Krajnje',
            header: 'Krajnje',
            accessorKey: 'Krajnje',
        },
        {
            id: 'Promet',
            header: 'Promet',
            accessorKey: 'Promet',
        },
        {
            id: 'Uposlenik',
            header: 'Uposlenik',
            accessorKey: 'uposlenik.UposlenikImePrezime',
        },
    ]
    const table2 = useReactTable({
        data: table2Data,
        columns: columns2,
        getCoreRowModel: getCoreRowModel(),
    })

    const columns3 = [
        {
            id: 'Id',
            header: 'Id',
            accessorKey: 'Id',
        },
        {
            id: 'Rolna',
            header: 'Rolna',
            accessorKey: 'RolnaID',
        },
        {
            id: 'IzLokacije',
            header: 'Iz Lokacije',
            accessorKey: 'IzLokacije',
        },
        {
            id: 'ULokaciju',
            header: 'U Lokaciju',
            accessorKey: 'ULokaciju',
        },
        {
            id: 'Datum',
            header: 'Datum',
            accessorFn: (row) => `${moments(row.Datum).format('DD-MM-YYYY HH:mm')}`,
        },
        {
            id: 'Uposlenik',
            header: 'Uposlenik',
            accessorKey: 'uposlenik.UposlenikImePrezime',
        },
    ]
    const table3 = useReactTable({
        data: table3Data,
        columns: columns3,
        getCoreRowModel: getCoreRowModel(),
    })
    return (
        <KTCard>
            <ToastContainer />
            <KTCardBody>
                {/* begin: Rolna Id  */}
                <div className='col'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>ROLNA</label>
                    <div className='row'>
                        <div className='col-lg-1 col-sm-4  mb-3'>
                            <QrCodeScanner
                                onScan={(data) => {
                                    const text = data.split(';')[0]
                                    setrolnaId(text)
                                }}
                            />
                        </div>
                        <div className='col-lg-4 col-sm-6  mb-3'>
                            <input
                                type='text'
                                placeholder='Rolna'
                                value={rolnaId}
                                className={clsx('form-control form-control-lg form-control-solid')}
                                onChange={(e) => setrolnaId(e.target.value)}
                            />
                        </div>
                        <div className='col-lg-2 col-sm-6 mb-3'>
                            <button
                                type='button'
                                className='btn btn-primary'
                                disabled={loading || !rolnaId}
                                onClick={getTableData}
                            >
                                {!loading && 'Potvrdi'}
                                {loading && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    <div className='col-lg-3 col-sm-6 mb-3'>
                        {table1Data?.stavka?.Atest && (
                            <div className='col-lg-6 col-offset-6'>
                                <h1>PREUZMI ATEST</h1>
                                <button
                                    className="btn btn-link p-0"
                                    onClick={() => window.open(table1Data?.stavka?.Atest, '_blank')}
                                >
                                    <i
                                        className="bi bi-file-earmark-pdf-fill mb-5"
                                        style={{ color: 'black', fontSize: '5.5rem' }}
                                    ></i>
                                </button>
                            </div>
                        )}
                        </div>
                    </div>
                </div>
                {/* end: Rolna Id  */}

                {/* begin:tables */}
                {table1Data && (
                    <div className='row mt-20'>
                        {/* begin: Table 1 */}
                        <div className='col-lg-4 col-sm-12'>
                            <div className='badge badge-light-info p-3 m-1'> Kartica</div>

                            <table className="table table-bordered">
                                <tbody className="">
                                    <tr>
                                        <td className="fw-bolder text-center fs-7 text-uppercase bg-secondary">Broj rolne</td>
                                        <td className="text-center">{table1Data?.ReprMatId}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bolder text-center fs-7 text-uppercase bg-secondary">S/N</td>
                                        <td className="text-center">{table1Data?.ReprMatSeriski}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bolder text-center fs-7 text-uppercase bg-secondary">Ulaz</td>
                                        <td className="text-center">{table1Data?.ReprMatUlazBroj}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bolder text-center fs-7 text-uppercase bg-secondary">Debljina</td>
                                        <td className="text-center">{table1Data?.ReprMatDebljina}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bolder text-center fs-7 text-uppercase bg-secondary">Širina</td>
                                        <td className="text-center">{table1Data?.ReprMatSirina}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bolder text-center fs-7 text-uppercase bg-secondary">Dužina</td>
                                        <td className="text-center">{table1Data?.ReprMatDuzina}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bolder text-center fs-7 text-uppercase bg-secondary">Netto</td>
                                        <td className="text-center">{table1Data?.ReprMatNettoKolicina}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bolder text-center fs-7 text-uppercase bg-secondary">Brutto</td>
                                        <td className="text-center">{table1Data?.ReprMatBruttoKolicina}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bolder text-center fs-7 text-uppercase bg-secondary"> Trenutna težina</td>
                                        <td className="text-center">{table1Data?.ReprMatTrenutnaKolicina}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bolder text-center fs-7 text-uppercase bg-secondary">Lokacija</td>
                                        <td className="text-center">
                                            {table1Data?.ReprMatLokacija ?? 'Nije upisana lokacija'} / {table1Data?.ReprMatLokacija2 ?? ''}

                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bolder text-center fs-7 text-uppercase bg-secondary"> Materijal</td>
                                        <td className="text-center"> {table1Data?.materijal?.MetaValue}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bolder text-center fs-7 text-uppercase bg-secondary"> Kvalitet</td>
                                        <td className="text-center">{table1Data?.kvalitet?.MatKvalitetNaziv}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bolder text-center fs-7 text-uppercase bg-secondary"> Zastita</td>
                                        <td className="text-center">{table1Data?.zastita?.MetaValue}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bolder text-center fs-7 text-uppercase bg-secondary">  Kod boje</td>
                                        <td className="text-center">{table1Data?.kod_boje?.KodBojeNaziv}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bolder text-center fs-7 text-uppercase bg-secondary">   Struktura A</td>
                                        <td className="text-center">{table1Data?.struktura_a?.MetaValue}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bolder text-center fs-7 text-uppercase bg-secondary">  Boja A</td>
                                        <td className="text-center">{table1Data?.boja_a?.MetaValue}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bolder text-center fs-7 text-uppercase bg-secondary">Struktura B</td>
                                        <td className="text-center">{table1Data?.struktura_b?.MetaValue}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bolder text-center fs-7 text-uppercase bg-secondary">Boja B</td>
                                        <td className="text-center">{table1Data?.boja_b?.MetaValue}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bolder text-center fs-7 text-uppercase bg-secondary">Kategorija</td>
                                        <td className="text-center">{table1Data?.kategorija?.KatNaziv}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bolder text-center fs-7 text-uppercase bg-secondary">Skladiste</td>
                                        <td className="text-center">  {table1Data?.skladiste?.SkladisteNaziv}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bolder text-center fs-7 text-uppercase bg-secondary">Status</td>
                                        <td className="text-center">{table1Data?.status?.Naziv}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bolder text-center fs-7 text-uppercase bg-secondary">Boja</td>
                                        <td className="text-center">{table1Data?.boja_lica?.BojaNaziv}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bolder text-center fs-7 text-uppercase bg-secondary">Boja nalicja</td>
                                        <td className="text-center"> {table1Data?.boja_nalicja?.BojaNaziv}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bolder text-center fs-7 text-uppercase bg-secondary">Proizvodjac</td>
                                        <td className="text-center"> {table1Data?.proizvodjac?.PartnerNaziv}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bolder text-center fs-7 text-uppercase bg-secondary">Dobavljac</td>
                                        <td className="text-center">  {table1Data?.dobavljac?.PartnerNaziv}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bolder text-center fs-7 text-uppercase bg-secondary">Stavka</td>
                                        <td className="text-center"> {table1Data?.stavka?.ManualId ?? ''}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bolder text-center fs-7 text-uppercase bg-secondary">   Ugovor</td>
                                        <td className="text-center">     {table1Data?.stavka?.ugovor?.BrojUgovora}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bolder text-center fs-7 text-uppercase bg-secondary">   Šarža</td>
                                        <td className="text-center">
                                            {parseFloat(table1Data?.stavka?.ugovor?.BrojUgovora ?? '0') /
                                                parseFloat(table1Data?.stavka?.ManualId ?? '1')}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bolder text-center fs-7 text-uppercase bg-secondary">   Garancija</td>
                                        <td className="text-center">     {table1Data?.garancija?.MetaValue}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {/* end: Table 1 */}

                        <div className='col-lg-8 col-sm-12'>
                            {/* begin: Table 2 */}

                            <div className='table-responsive'>
                                <div className='badge badge-light-info p-3 m-1'> Promet</div>
                                <table className='table align-middle table-bordered gy-5 no-footer'>
                                    <thead>
                                    {table2.getHeaderGroups().map((headerGroup) => (
                                        <tr
                                            key={headerGroup.id}
                                            className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                                        >
                                            {headerGroup.headers.map((header) => (
                                                <th
                                                    className='text-white'
                                                    key={header.id}
                                                    onClick={header.column.getToggleSortingHandler()}
                                                >
                                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                                    {/* @ts-ignore */}
                                                    {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                    </thead>
                                    <tbody className='text-gray-600'>
                                    {table2.getRowModel().rows && table2.getRowModel().rows.length > 0 ? (
                                        table2.getRowModel().rows.map((row) => (
                                            <tr key={row.id}>
                                                {row.getVisibleCells().map((cell) => (
                                                    <td key={cell.id}>
                                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={7}>
                                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                    No matching records found
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                            {/* end: Table 2 */}
                            {/* begin: Table 3 */}
                            <div className='table-responsive mt-3 '>
                                <div className='badge badge-light-info p-3 m-1'>Izmjene Lokacije</div>
                                <table className='table align-middle table-bordered gy-5 no-footer'>
                                    <thead>
                                    {table3.getHeaderGroups().map((headerGroup) => (
                                        <tr
                                            key={headerGroup.id}
                                            className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                                        >
                                            {headerGroup.headers.map((header) => (
                                                <th
                                                    className='text-white'
                                                    key={header.id}
                                                    onClick={header.column.getToggleSortingHandler()}
                                                >
                                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                                    {/* @ts-ignore */}
                                                    {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                    </thead>
                                    <tbody className='text-gray-600'>
                                    {table3.getRowModel().rows && table3.getRowModel().rows.length > 0 ? (
                                        table3.getRowModel().rows.map((row) => (
                                            <tr key={row.id}>
                                                {row.getVisibleCells().map((cell) => (
                                                    <td key={cell.id}>
                                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={7}>
                                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                    No matching records found
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                            {/* end: Table 3 */}
                        </div>
                    </div>
                )}
                {/* end:tables */}
            </KTCardBody>
        </KTCard>
    )
}

export default KarticaRolne
