import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import 'react-toastify/dist/ReactToastify.css'
import notifyToast from '../../components/Toast/Toast'
import {ToastContainer} from 'react-toastify'
import TitleComponent from '../../components/TitleComponent/TitleComponent'
import {useParams} from 'react-router-dom'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import {ThermoBoardNalozi} from "../../models/ThermoBoardNalozi";
import {fetchTBNalog, saveTBNalog, updateTBNalog} from "../../api/thermoboardNalozi";
import PanelTipDropdown from "../paneli/components/PanelTipDropdown/PanelTipDropdown";

interface Props {
    // other potential props go here
}

const ThermoBoardDetails: React.FC<Props> = () => {
    const {id} = useParams()
    const IdFromURL = Number(id)

    const [loading, setLoading] = useState(true)

    const initialValues = {
        TipPanela: '',
        BrojNaloga: '',
        Kolicina: '',
        TipPaketa: '',
        BrojPaketa: '',
        Napomena: '',
    }

    const [initialData, setInitialData] = useState<Partial<ThermoBoardNalozi>>(initialValues)

    useEffect(() => {
        if (IdFromURL) {
            fetchTBNalog(IdFromURL)
                .then((data) => {
                    setInitialData(data)
                    console.log(data)
                    formik.resetForm({values: data}) // <-- reset formik state here
                })
                .finally(() => setLoading(false))
        } else {
            setLoading(false)
        }
    }, [IdFromURL])

    const validationSchema = Yup.object({
        BrojNaloga: Yup.string().min(4, 'Must be at least 4 characters').required('Required'),
        BrojPaketa: Yup.string().min(1, 'Must be at least 4 characters').required('Required'),
        Kolicina: Yup.string().min(1, 'Must be at least 4 characters').required('Required'),
    })

    const formik = useFormik({
        initialValues: initialData,
        validationSchema: validationSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            try {
                let response
                if (IdFromURL) {
                    response = await updateTBNalog(values, IdFromURL)
                } else {
                    response = await saveTBNalog(values)
                }
                const responseData = response

                if (responseData.error === 0) {
                    notifyToast(true, responseData.message)
                } else {
                    notifyToast(false, responseData.message || 'An unexpected error occurred.')
                }
            } catch (error) {
                console.error('Error: ', error)
                notifyToast(false, 'Error')
                setStatus(error)
            } finally {
                setLoading(false)
                setSubmitting(false)
            }
        },
    })
    return (
        <div className='card mb-5 mb-xl-10'>
            <ToastContainer />
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <TitleComponent name='' />
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                {loading ? (
                    <LoadingComponent />
                ) : (
                    <form onSubmit={formik.handleSubmit} noValidate className='form'>
                        <div className='card-body border-top p-9'>
                            {/* Broj naloga */}
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                    Broj Naloga
                                </label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='text'
                                        placeholder='Broj Naloga'
                                        {...formik.getFieldProps('BrojNaloga')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.BrojNaloga && formik.errors.BrojNaloga},
                                            {
                                                'is-valid': formik.touched.BrojNaloga && !formik.errors.BrojNaloga,
                                            }
                                        )}
                                    />
                                    {formik.touched.BrojNaloga && formik.errors.BrojNaloga && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.BrojNaloga}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/*Sifra Artikla*/}
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                    Šifra Artikla
                                </label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='text'
                                        placeholder='Sifra Artikla'
                                        {...formik.getFieldProps('SifraArtikla')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.SifraArtikla && formik.errors.SifraArtikla},
                                            {
                                                'is-valid': formik.touched.SifraArtikla && !formik.errors.SifraArtikla,
                                            }
                                        )}
                                    />
                                    {formik.touched.SifraArtikla && formik.errors.SifraArtikla && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.SifraArtikla}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* Tip panela */}
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                    <span className='required'>Tip Panela</span>
                                </label>
                                <div className='col-lg-8 fv-row'>
                                    <PanelTipDropdown
                                        value={formik.values.TipPanela}
                                        onChange={(name, value) =>
                                            formik.setFieldValue('TipPanela', value)
                                        }
                                        errors={formik.errors.TipPanela}
                                        touched={formik.touched.TipPanela}
                                        selectName='TipPanela'
                                    />
                                </div>
                            </div>
                            {/* Naziv */}
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                   Količina
                                </label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='text'
                                        placeholder='Kolicina'
                                        {...formik.getFieldProps('Kolicina')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.Kolicina && formik.errors.Kolicina},
                                            {
                                                'is-valid': formik.touched.Kolicina && !formik.errors.Kolicina,
                                            }
                                        )}
                                    />
                                    {formik.touched.Kolicina && formik.errors.Kolicina && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.Kolicina}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                    Broj Paketa
                                </label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='number'
                                        placeholder='Broj Paketa'
                                        {...formik.getFieldProps('BrojPaketa')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.BrojPaketa && formik.errors.BrojPaketa},
                                            {
                                                'is-valid': formik.touched.BrojPaketa && !formik.errors.BrojPaketa,
                                            }
                                        )}
                                    />
                                    {formik.touched.BrojPaketa && formik.errors.BrojPaketa && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.BrojPaketa}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                    <span className='required'>Tip paketa</span>
                                </label>

                                <div className='col-lg-8 fv-row'>
                                    <select
                                        {...formik.getFieldProps('TipPaketa')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.TipPaketa && formik.errors.TipPaketa},
                                            {
                                                'is-valid': formik.touched.TipPaketa && !formik.errors.TipPaketa,
                                            }
                                        )}
                                    >
                                        <option value=''>Select...</option>
                                        <option value='1'>Mega</option>
                                        <option value='0'>Obično</option>
                                    </select>
                                    {formik.touched.TipPaketa && formik.errors.TipPaketa && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.TipPaketa}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* Naziv */}
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Napomena</label>
                                <div className='col-lg-8 fv-row'>
                                  <textarea
                                      placeholder='Napomena'
                                      {...formik.getFieldProps('Napomena')}
                                      className={clsx(
                                          'form-control form-control-lg form-control-solid',
                                          {'is-invalid': formik.touched.Napomena && formik.errors.Napomena},
                                          {
                                              'is-valid': formik.touched.Napomena && !formik.errors.Napomena,
                                          }
                                      )}
                                  />
                                    {formik.touched.Napomena && formik.errors.Napomena && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.Napomena}</div>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>
                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <button
                                type='submit'
                                className='btn btn-primary'
                                disabled={loading || formik.isSubmitting || !formik.isValid}
                                >
                                {!loading && 'Potvrdi'}
                                {loading && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
                                            Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                         </span>
                                )}
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    )
    }
export default ThermoBoardDetails;
