import * as Yup from "yup";
import React, { useEffect, useState, useCallback } from "react";
import { useFormik } from "formik";
import { RepromaterijalIzmjena } from "../../../models/RepromaterijaliModel";
import { updateLokacijaRolneRepromaterijala } from "../../../api";
import notifyToast from "../../../components/Toast/Toast";
import { getRolnaDetalji } from "../../../api/rolneDetalji";
import { ToastContainer } from "react-toastify";
import TitleComponent from "../../../components/TitleComponent/TitleComponent";
import QrCodeScanner from "../../../components/QrCodeScanner/QrCodeScanner";
import clsx from "clsx";
import LokacijeRsTraka from "../../../components/LokacijeRSTraka/LokacijeRsTraka";
import { fetchTable, updateTable } from "../../../api/tableRS";

const IzmjenaStatusaILokacijeTrake = () => {
    const [loading, setLoading] = useState(false);
    const [dostupnostRolne, setDostupnostRolne] = useState("");
    const [reprMatLokacija, setReprMatLokacija] = useState("");

    const formik = useFormik({
        initialValues: {
            stavkaId: "",
            lokacija: "",
        },
        validationSchema: Yup.object({
            stavkaId: Yup.string().required("Required"),
            lokacija: Yup.string().required("Required"),
        }),
        onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
            setLoading(true);
            try {
                const stavkaIdNum = parseInt(values.stavkaId, 10);
                if (!isNaN(stavkaIdNum)) {
                    const data = { Id: stavkaIdNum, Lokacija: values.lokacija };
                    const response = await updateTable(data, stavkaIdNum);

                    if (response?.error === 0) {
                        notifyToast(true, response.message);
                        resetForm();
                        formik.setFieldValue('lokacija', "");
                        setDostupnostRolne("");
                        setReprMatLokacija("");


                    } else {
                        notifyToast(false, response?.message || "An unexpected error occurred.");
                    }
                } else {
                    notifyToast(false, "Invalid stavkaId");
                }
            } catch (error) {
                console.error("Error: ", error);
                notifyToast(false, "Error");
                setStatus(error);
            }
            setSubmitting(false);
            setLoading(false);
        },
    });

    const fetchRolnaDetalji = useCallback(async () => {
        if (formik.values.stavkaId.length >= 0) {
            const reprMatIdNum = parseInt(formik.values.stavkaId, 10);
            if (!isNaN(reprMatIdNum) && reprMatIdNum > 0) {
                try {
                    const response = await fetchTable(reprMatIdNum);
                    setDostupnostRolne(response?.Tezina || "");
                    setReprMatLokacija(response?.Lokacija || "");
                } catch (error) {
                    console.error("Error fetching Rolna details:", error);
                }
            }
        }
    }, [formik.values.stavkaId]);

    useEffect(() => {
        fetchRolnaDetalji();
    }, [fetchRolnaDetalji]);

    return (
        <div className='card mb-5 mb-xl-10'>
            <ToastContainer/>
            <div className='card-header border-0 cursor-pointer'>
                <div className='card-title m-0'>
                    <TitleComponent name='lokacije i statusa trake'/>
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    <div className='card-body border-top p-9'>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>TRAKA</label>
                            <div className='col-lg-12 mb-6'>
                                <div className='row mb-5'>
                                    <div className='col-sm-2 col-xs-4'>
                                        <QrCodeScanner onScan={(data) => formik.setFieldValue('stavkaId', data)}/>
                                    </div>
                                    <div className='col-sm-4 col-xs-6'>
                                        <input
                                            type='text'
                                            placeholder='Traka'
                                            {...formik.getFieldProps('stavkaId')}
                                            className={clsx('form-control form-control-lg form-control-solid', {
                                                'is-invalid': formik.touched.stavkaId && formik.errors.stavkaId,
                                                'is-valid': formik.touched.stavkaId && !formik.errors.stavkaId,
                                            })}
                                            autoFocus
                                        />
                                        {formik.touched.stavkaId && formik.errors.stavkaId && (
                                            <div className='fv-help-block'>{formik.errors.stavkaId}</div>
                                        )}
                                    </div>
                                    <div className='col-sm-4 col-xs-6'>
                                        <input
                                            type='text'
                                            placeholder={loading ? 'Loading...' : 'Težina trake'}
                                            className='form-control form-control-lg form-control-solid disabled'
                                            value={loading ? '' : dostupnostRolne}
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div className='row mt-5'>
                                    <div className='col-sm-4 offset-sm-2'>
                                        <input
                                            type='text'
                                            placeholder={loading ? 'Loading...' : 'Trenutna lokacija'}
                                            className='form-control form-control-lg form-control-solid disabled'
                                            value={loading ? '' : reprMatLokacija}
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='separator'></div>
                            <div className='col-lg-8 mt-5'>
                                <label className='fw-bold fs-6'>Izmjena lokacije</label>
                                <LokacijeRsTraka
                                    value={formik.values.lokacija}
                                    onChange={(name, value) => formik.setFieldValue('lokacija', value)}
                                    errors={formik.errors.lokacija}
                                    touched={formik.touched.lokacija}
                                    selectName='lokacija'
                                />
                            </div>
                        </div>
                    </div>
                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button type='submit' className='btn btn-primary' disabled={loading || !formik.isValid}>Potvrdi</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default IzmjenaStatusaILokacijeTrake;
